$(document).ready(function()
{
    // form elements
    initFormElements();

    // init scroll handling
    initScrollHandling();

    // detect browser
    initBrowserDetection();

    // install default button
    $('body').installDefaultButton();

    // set default focus
    focusFirstInputError();

    // init handler for internal toggle links
    initToggleHandler();

    // disable inactive links
    disableInactiveLinks();

    // init datatables
    initDatatables();

    // svg legacy support
    if (typeof svg4everybody === "function")
    {
        svg4everybody();
    }

	if (document.msCapsLockWarningOff == false)
	{
	     document.msCapsLockWarningOff = true;
	}
});

function initFormElements()
{
    // select
    $('.selectpicker').selectpicker();

    // tooltips
    initTooltip();
}

function initTooltip()
{
    // initializing popovers everywhere
	const popovers = document.querySelectorAll('[data-bs-toggle="popover"]');
	popovers.forEach(pover => { new bootstrap.Popover(pover, { html: true, trigger: 'hover click' } ); })
}

function initDatePicker(selector, minDate, maxDate)
{
    initDatePicker(selector, minDate, maxDate, true);
}

function initDatePicker(selector, minDate, maxDate, forceParse)
{
    if($(selector).parents('.date').datepicker != undefined)
    {
        $(selector).parents('.date').datepicker('remove');
    }
    $(selector).parents('.date').datepicker({
        format: 'dd.mm.yyyy',
        language: (datepickerLocale !== 'undefined' && datepickerLocale != null) ? datepickerLocale : 'de',
        autoclose: true,
        todayHighlight: true,
        startDate: createDate(minDate),
        endDate: createDate(maxDate),
        forceParse: forceParse,
        orientation: 'auto'
    });
}

function initError(currentSelector)
{
    var tooltipIcon = $(currentSelector).parents('.input-group').children('.popover-tooltip');
    $(currentSelector).focus(function()
    {
        tooltipIcon.popover('show');
        $('.popover').addClass('alert-danger');
    });
    $(currentSelector).blur(function()
    {
        tooltipIcon.popover('hide');
        $('.popover').removeClass('alert-danger');
    });
}

function initFooterHandling()
{
    fixingTheFooter();
    $(window).resize(function() {
        fixingTheFooter();
    });
}

function initScrollHandling()
{
    $(window).scroll(function()
    {
        if( $('#headMenu').length )
        {
        	if( (getHeightOfElement('header') + ($('header').offset().top - $(window).scrollTop())) < 0 )
            {
                $('#headMenu').addClass('fixed');
                $('#main, #accordionMenu').css('margin-top', getHeightOfElement('#headMenu') + getHeightOfElement('#topMenu nav') + 'px' );
                $('#topMenu').addClass('fixed-with-headmenu');

                if( $('#close-process').length )
                {
                	$('#close-process').css('top', ($(window).scrollTop() - getHeightOfElement('header') ) + 'px' );
                    $('#close-process').addClass('shadowed');
                }
            }
            else
            {
                $('#headMenu').removeClass('fixed');
                $('#topMenu').removeClass('fixed-with-headmenu');
                $('#main, #accordionMenu').css('margin-top', '0px' );

                if( $('#close-process').length ) {
                    $('#close-process').css('top', '0px');
				    $('#close-process').removeClass('shadowed');
                }
            }
        }
        else if( $('#topMenu').length )
        {
            if( (getHeightOfElement('header') + ($('header').offset().top - $(window).scrollTop())) < 0 )
            {
                $('#main').css('margin-top', getHeightOfElement('#topMenu nav') + 'px' );
                $('#topMenu').addClass('fixed');

                if( $('#close-process').length )
                {
                    $('#close-process').css('top', ($(window).scrollTop() - getHeightOfElement('header') ) + 'px');
					$('#close-process').addClass('shadowed');
                }
            }
            else
            {
            	$('#topMenu').removeClass('fixed');
            	$('#main').css('margin-top', '0px' );

	            if( $('#close-process').length )
	            {
	                $('#close-process').css('top', '0px');
					$('#close-process').removeClass('shadowed');
	            }
            }
        }
    });
}

function initToggleHandler() {
    $('[id$=CollapseMenuToogle]').click(function()
    {
        var id = $(this).attr('id').trim();
        var targetId = id.substring(0, id.lastIndexOf("Toogle"));
        $('#' + targetId).collapse('toggle');
    });
}

function focusFirstInputError()
{
    if ($(window).width() > 767)
    {
        var errorInputs = $('input.alert-danger, textarea.alert-danger, div.alert-danger > button.selectpicker, div.radioCheckError');
        if(errorInputs.length > 0 && !errorInputs.first().parent().hasClass('date'))
        {
            errorInputs.first().focus();
        }
    }
}

function initBrowserDetection()
{
    // legacy browser detect
    BrowserDetect.init();

    // detect via bowser plugin
    if (typeof bowser !== 'undefined')
    {
        if ((bowser.msie && bowser.version < 10) ||
            (bowser.firefox && bowser.version < 24) ||
            (bowser.safari && bowser.version < 7) ||
            (bowser.chrome && bowser.version < 32) ||
            (bowser.opera && bowser.version < 15))
        {
            $('#browser-notification').show();
        }
    }
}

function initDatatables()
{
    if(typeof $.fn.dataTable !== 'undefined')
    {
        $.fn.dataTable.moment('DD.MM.YYYY');
        $.extend( true, $.fn.dataTable.defaults, {
            searching: false,
            lengthChange: false,
            language: {
                'sEmptyTable':      'Keine Daten in der Tabelle vorhanden',
                'sInfo':            '_START_ bis _END_ von _TOTAL_ Eintr&auml;gen',
                'sInfoEmpty':       '0 bis 0 von 0 Eintr&auml;gen',
                'sInfoFiltered':    '(gefiltert von _MAX_ Eintr&auml;gen)',
                'sInfoPostFix':     '',
                'sInfoThousands':   '.',
                'sLengthMenu':      '_MENU_ Eintr&auml;ge anzeigen',
                'sLoadingRecords':  'Wird geladen...',
                'sProcessing':      'Bitte warten...',
                'sSearch':          'Suchen',
                'sZeroRecords':     'Keine Eintr&auml;ge vorhanden.',
                'oPaginate': {
                    'sFirst':       'Erste',
                    'sPrevious':    'Zur&uuml;ck',
                    'sNext':        'N&auml;chste',
                    'sLast':        'Letzte'
                },
                'oAria': {
                    'sSortAscending':  ': aktivieren, um Spalte aufsteigend zu sortieren',
                    'sSortDescending': ': aktivieren, um Spalte absteigend zu sortieren'
                }
            }
        });
    }
}

function getPreselectedParams(elementAndParamName)
{
    var param = '';
    var preselections = $('input:checkbox[name=' + elementAndParamName + ']:checked');
    if (preselections.length > 0)
    {
        param += '?';
        jQuery.each(preselections, function(index, element)
        {
            param += elementAndParamName + '=' + $(element).val();
            param += (preselections.length > index + 1) ? '&' : '';
        });
    }
    return param;
}

function initPasswordVerification(selector, verificationControllerUrl)
{
    // init vars
    var passwordInput = jQuery(selector);
    var passwordInfoIcon = passwordInput.parent().find('span[data-bs-toggle="popover"]');
    if (passwordInfoIcon)
    {
        // store default info message
        var defaultInfoMessage = passwordInfoIcon.attr('data-bs-content');
        if (defaultInfoMessage && defaultInfoMessage != '')
        {
            passwordInfoIcon.data('defaultInfoMessage',defaultInfoMessage);
        }
    }

    jQuery(selector).on('input blur change focus', function (event)
    {
        var showPopover = passwordInput.is(':focus');

        if (passwordInput.val() && passwordInput.val() != '')
        {
            jQuery.ajax({
                type: "POST",
                dataType: "json",
                url: verificationControllerUrl,
                data: {"password": passwordInput.val()},
                complete: function (xhr, statusText)
                {
                    var jsonData = xhr.responseJSON;
                    if (jsonData)
                    {
                        var passwordStrength = (jsonData.passwordStrength && jsonData.passwordStrength > 0 ) ? jsonData.passwordStrength : 1;
                        var indicatorClass = '';
                        switch (passwordStrength)
                        {
                            case 3:
                                indicatorClass = 'alert-password-strong';
                                break;
                            case 2:
                                indicatorClass = 'alert-password-medium';
                                break;
                            case 1:
                            default:
                                indicatorClass = 'alert-password-weak';
                                break;
                        }
                        passwordInput.removeClass('alert-password-weak alert-password-medium alert-password-strong').addClass(indicatorClass);

                        if (passwordInfoIcon)
                        {
                            var infoText = (jsonData.infoMessage != '' ) ? jsonData.infoMessage : passwordInfoIcon.data('defaultInfoMessage');
                            var oldInfoText = passwordInfoIcon.attr('data-bs-content');
                            passwordInfoIcon.removeClass('alert-default alert-danger alert-info alert-password-weak alert-password-medium alert-password-strong').addClass(indicatorClass);
                            $('.popover-body').html(infoText);
                            if ((oldInfoText != infoText) && showPopover || event.type == 'focus')
                            {
                                if (passwordStrength != 3)
                                {
                                    jQuery(passwordInfoIcon).popover('show');
                                }
                                else
                                {
                                    jQuery(passwordInfoIcon).popover('hide');
                                }
                            }
                        }
                    }
                }
            });
        }
        else
        {
            passwordInput.removeClass('alert-password-weak alert-password-medium alert-password-strong');
            passwordInfoIcon.removeClass('alert-default alert-danger alert-info alert-password-weak alert-password-medium alert-password-strong');
            if (passwordInfoIcon)
            {
                passwordInfoIcon.attr('data-bs-content', passwordInfoIcon.data('defaultInfoMessage'));
                jQuery(passwordInfoIcon).popover('hide');
            }
        }

        // hide popover on blur
        if (!showPopover)
        {
            jQuery(passwordInfoIcon).popover('hide');
        }
    });
}

function startProcess(url)
{
    window.location = (url);
}

//integrate string underscore into the global context
if (typeof _.mixin != "undefined" && typeof s != "undefined")
{
    _.mixin(s.exports());
}

function getHeightOfElement(anElement)
{
    var heightOfElement = parseInt($(anElement).css('height'));
        heightOfElement += getMetaHeightOfElement(anElement);
    return heightOfElement;
}

function getMetaHeightOfElement(anElement)
{
    var metaHeightOfElement = parseInt($(anElement).css('margin-top'));
        metaHeightOfElement += parseInt($(anElement).css('margin-bottom'));
        metaHeightOfElement += parseInt($(anElement).css('padding-top'));
        metaHeightOfElement += parseInt($(anElement).css('padding-bottom'));
        metaHeightOfElement += parseInt($(anElement).css('border-top-width'));
        metaHeightOfElement += parseInt($(anElement).css('border-bottom-width'));
    return metaHeightOfElement;
}

function getWidthOfElement(anElement)
{
    var widthOfElement = parseInt($(anElement).css('width'));
        widthOfElement += getMetaWidthOfElement(anElement);
    return widthOfElement;
}

function getMetaWidthOfElement(anElement)
{
    var metaWidthOfElement = parseInt($(anElement).css('margin-left'));  //incl. auto
        metaWidthOfElement += parseInt($(anElement).css('margin-right'));  //incl. auto
        metaWidthOfElement += parseInt($(anElement).css('padding-left'));
        metaWidthOfElement += parseInt($(anElement).css('padding-right'));
        metaWidthOfElement += parseInt($(anElement).css('border-left-width'));
        metaWidthOfElement += parseInt($(anElement).css('border-right-width'));
    return metaWidthOfElement;
}

function loaderAnimation(selector, handler)
{
    if (selector.length > 0)
    {
        selector.html('&nbsp;');
        return setInterval(function() {
            if(selector.html() == '&nbsp;')
            {
                selector.html('&bull;');
            }
            else if (selector.html().length < 4)
            {
                selector.append('&bull;');
            }
            else
            {
                selector.html('&nbsp;');
            }
        }, 450);
    }
}

//----------------------------------------------------------------------------------------------------------------------

/**
 * jQuery namespace inclusion.
 */
(function($)
{
    jQuery.fn.initDatePicker = function(minDate, maxDate)
    {
        initDatePicker($(this), minDate, maxDate);
    }
})(jQuery);

// ---------------------------------------------------------------------------------------------------------------------

function showUnreadPostboxDocumentsInfo(unreadCount)
{
    var postboxLink = $('a#head-menu-postbox-link');
    if(unreadCount > 0)
    {
        if($(postboxLink).find('.using-glyphicons').hasClass('no-news') || parseInt($(postboxLink).find('.badge').text()) != unreadCount)
        {
            $(postboxLink).find('.using-glyphicons').removeClass('no-news');
            $(postboxLink).find('.badge').text(unreadCount).show();
        }
    }
    else
    {
        // reset postbox info
        $(postboxLink).find('.using-glyphicons').addClass('no-news');
        $(postboxLink).find('.badge').text("").hide();
    }
}

// ---------------------------------------------------------------------------------------------------------------------

function disableInactiveLinks()
{
    $('a').on('click', function(event){
        if ($(this).hasClass('inactive')) {
            event.preventDefault();
        }
    });
}
